import { TFunction } from 'react-i18next';
import { InputTypes } from './EditableCell';
import { EditableDetailsTableTypes } from 'enums';

export const makeColumnsEditableTable = (t:TFunction, tableType: EditableDetailsTableTypes, editable: boolean) => {
  const palletsProportionsArray = [
    {
      title: t('forms.newEstimation.quantity'),
      dataIndex: 'item_quantity',
      inputType: InputTypes.quantyty_number,
      editable: true,
      width: '10%',
      align: 'center',
    },
    {
      title: t('forms.newEstimation.shortSide'),
      dataIndex: 'depth',
      inputType: InputTypes.depth_number,
      editable: true,
      width: '10%',
      align: 'center',
    },
    {
      title: t('forms.newEstimation.longSide'),
      dataIndex: 'width',
      inputType: InputTypes.width_number,
      editable: true,
      width: '10%',
      align: 'center',
    },
    {
      title: t('forms.newEstimation.height'),
      dataIndex: 'height',
      inputType: InputTypes.height_number,
      editable: true,
      width: '10%',
      align: 'center',
    },
    {
      title: t('forms.newEstimation.weight'),
      dataIndex: 'weight',
      inputType: InputTypes.weight_number,
      editable: true,
      width: '10%',
      align: 'center',
    },
  ];
  const palletsPriceArray = [
    {
      title: t('forms.newEstimation.base_price'),
      dataIndex: 'base_price',
      editable: false,
      width: '10%',
      align: 'center',
    },
    {
      title: '% ripr.',
      dataIndex: 'coef',
      editable: false,
      align: 'center',
    },
  ];
  switch (tableType) {
  case EditableDetailsTableTypes.invoice_pallets:
    return [
      ...palletsProportionsArray,
      {
        title: t('invoices.table.repriced'),
        dataIndex: 'show',
        inputType: InputTypes.select_show,
        editable: true,
        width: '10%',
        align: 'center',
      },
      ...palletsPriceArray,
      {
        title: t('invoices.table.price'),
        dataIndex: 'price',
        inputType: InputTypes.pallet_price,
        editable: true,
        width: '10%',
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '12%',
      },
    ];
  case EditableDetailsTableTypes.admin_pallets:
    return [
      ...palletsProportionsArray,
      ...palletsPriceArray,
      {
        title: t('invoices.table.price'),
        dataIndex: 'price',
        inputType: InputTypes.pallet_price,
        editable: false,
        width: '10%',
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '12%',
      },
    ];
  case EditableDetailsTableTypes.private_area_pallets:
    return [
      ...palletsProportionsArray,
      {
        title: '',
        dataIndex: 'options',
        width: '12%',
      },
    ];
  case EditableDetailsTableTypes.options:
    return [
      {
        title: t('invoices.table.quantity'),
        dataIndex: 'quantity',
        inputType: InputTypes.simple_number,
        editable: true,
        width: '13%',
      },
      {
        title: t('invoices.table.options_title'),
        dataIndex: 'title',
        editable: false,
      },
      {
        title: t('forms.newEstimation.service_details'),
        dataIndex: 'description',
        inputType: InputTypes.text,
        editable: true,
      },
      {
        title: t('invoices.table.unit_cost'),
        dataIndex: 'price',
        inputType: InputTypes.select_price,
        editable: true,
        width: '15%',
      },
      {
        title: t('forms.newEstimation.requested_to'),
        dataIndex: 'requested_to',
        key: 'requested_to',
        inputType: InputTypes.requested_to,
        width: '20%',
        editable,
      },
      {
        title: t('forms.newEstimation.fixed_minimum'),
        dataIndex: 'fixedMin',
        key: 'fixedMin',
        align: 'center',
        inputType: InputTypes.fixedMinPrice,
        editable: true,
        render: (_, record) => (
          <>
            {record.fixedMin ? `${record.fixedMin}€` : '-'}
          </>
        ),
      },
      {
        title: t('forms.newEstimation.apply_to'),
        dataIndex: 'category',
        inputType: InputTypes.select_category,
        editable: true,
        width: '15%',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '15%',
      },
    ];
  case EditableDetailsTableTypes.private_area_options:
    return [
      {
        title: t('forms.newEstimation.service_name'),
        dataIndex: 'title',
        inputType: InputTypes.text,
        width: '45%',
        editable: true,
      },
      {
        title: t('forms.newEstimation.service_details'),
        dataIndex: 'description',
        inputType: InputTypes.text,
        width: '45%',
        editable: true,
      },
      {
        title: t('forms.newEstimation.requested_to'),
        dataIndex: 'requested_to',
        key: 'requested_to',
        inputType: InputTypes.requested_to,
        width: '45%',
        editable,
      },
      {
        title: '',
        dataIndex: 'options',
        width: '15%',
      },
    ];
  case EditableDetailsTableTypes.addebitos:
    return [
      {
        title: t('invoices.table.quantity'),
        dataIndex: 'quantity',
        inputType: InputTypes.quantyty_number,
        editable: true,
        width: '13%',
      },
      {
        title: t('invoices.table.charge_description'),
        dataIndex: 'description',
        inputType: InputTypes.text,
        editable: true,
      },
      {
        title: t('invoices.table.unit_cost'),
        dataIndex: 'price',
        inputType: InputTypes.select_negative_price,
        editable: true,
        width: '15%',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '15%',
      },
    ];
  case EditableDetailsTableTypes.merce_adr:
    return [
      {
        title: t('forms.newEstimation.merce_adr_limited_qty'),
        dataIndex: 'limited_qty',
        inputType: InputTypes.select_adr_limit,
        editable: true,
        width: '13%',
      },
      {
        title: t('forms.newEstimation.merce_adr_number_onu'),
        dataIndex: 'numeroOnu',
        inputType: InputTypes.select_numero_onu,
        editable,
        width: '20%',
      },
      {
        title: t('forms.newEstimation.merce_adr_qty'),
        dataIndex: 'quantita',
        inputType: InputTypes.simple_number,
        editable,
        width: '13%',
      },
      {
        title: t('forms.newEstimation.merce_adr_packages'),
        dataIndex: 'numeroColli',
        inputType: InputTypes.simple_number,
        editable,
        width: '13%',
      },
      {
        title: t('forms.newEstimation.merce_adr_packaging_description'),
        dataIndex: 'imballaggioMerceAdr',
        inputType: InputTypes.select_imballaggio,
        editable,
        width: '13%',
      },
      {
        title: t('forms.newEstimation.merce_adr_description_nos'),
        dataIndex: 'descrizioneNas',
        inputType: InputTypes.descrizione_text,
        editable,
        width: '13%',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '13%',
      },
    ];
  default:
    return [];
  }
};
