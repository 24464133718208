import { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import CustomButton from '../../components/CustomButton/CustomButton';
import { User } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestChangePass, setSuccessPasswordChange } from '../../store/redux_slice/settingsSlice';
import style from './profile.module.css';

const PasswordForm = ({ userData }: { userData: User | null }) => {
  const { t } = useTranslation();
  const [passForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const changePassSuccess = useAppSelector((state) => state.settings.changePassSuccess);

  useEffect(() => {
    if (changePassSuccess) {
      passForm.resetFields();
      dispatch(setSuccessPasswordChange(false));
    }
  }, [changePassSuccess, passForm, dispatch]);

  const onFinish = (values) => {
    dispatch(requestChangePass({ email: userData?.email, ...values }));
  };
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={passForm}
    >
      <div className={style.password_form_container}>
        <div className={classnames('main-title-30px', style.bottom)}>
          {t('operatorSettings.update_password')}
        </div>
        <Form.Item
          label={t('operatorSettings.old_password')}
          name="oldPass"
          className={classnames(style.text_style, style.blue)}
          rules={[{ required: true, message: t('operatorSettings.oldPass') },
            { min: 8, message: t('validation.password') },
            { whitespace: true, message: t('validation.password') },
          ]}
        >
          <Input.Password className={style.input} />
        </Form.Item>
        <Form.Item
          label={t('operatorSettings.new_password')}
          name="newPass"
          className={classnames(style.text_style, style.blue)}
          rules={[{ required: true, message: t('operatorSettings.newPass') },
            { min: 8, message: t('validation.password') },
            { whitespace: true, message: t('validation.password') },
            () => ({
              validator(rules, value) {
                const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
                const hasUppercase = /[A-Z]/.test(value);
                if (hasSpecialChar && hasUppercase) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('validation.easy_password')));
              },
            }),
          ]}
        >
          <Input.Password className={style.input}/>
        </Form.Item>
        <Form.Item
          label={t('operatorSettings.rewrite_new_password')}
          dependencies={['newPass']}
          hasFeedback
          name="confirmPass"
          className={classnames(style.text_style, style.blue)}
          rules={[{ required: true, message: t('operatorSettings.confirmPass') },
            { min: 8, message: t('validation.password') },
            { whitespace: true, message: t('validation.password') },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPass') === value) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(t('operatorSettings.notMatch'));
              },
            }),
          ]}
        >
          <Input.Password className={style.input}/>
        </Form.Item>
        <CustomButton
          type="primary"
          htmlType="submit"
          className={style.confirmBtn}
        >
          {t('operatorSettings.update_password')}
        </CustomButton>
      </div>
    </Form>
  );
};

export default PasswordForm;
