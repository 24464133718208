import {
  Button, DatePicker, Form, FormInstance, Row, Col, TimePicker,
} from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import GooglePlacesComponent from 'components/GooglePlacesComponent';
import CustomButton from 'components/CustomButton/CustomButton';
import PalletDetails from './PalletDetails';
import ShipmentRequestStepTwo from '../ShipmentRequestStepTwo';
import {
  ContrassegnoDto, ExtraServiceStructure, Facchinaggio, Pallet,
} from 'store/types';
import { AddressFields, DeliveryType } from 'enums';
import style from './ShipmentRequestStepOne.module.css';

interface ShipmentRequestFormInterface {
  firstStep: boolean,
  onFinish: (Record) => void,
  countArray: number[],
  isDeletePallet: boolean,
  onDeletePallet: (index) => void,
  setIsDeletePallet: (boolean) => void,
  onChangeStep: () => void,
  onAddPallet: () => void,
  extraServices: ExtraServiceStructure[],
  form: FormInstance,
  facchinaggioList: Facchinaggio[],
  contrassegnoList: ContrassegnoDto[],
  pallets: Pallet[],
}

const ShipmentRequestForm = ({
  firstStep,
  onFinish,
  countArray,
  isDeletePallet,
  onDeletePallet,
  setIsDeletePallet,
  onChangeStep,
  extraServices,
  onAddPallet,
  form,
  facchinaggioList,
  contrassegnoList,
  pallets,
} : ShipmentRequestFormInterface) => {
  const { t } = useTranslation();

  return (
    <Form
      form={form}
      name="shipment_request"
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        user_type: 'Company',
      }}
    >
      <div className={firstStep ? style.visible : style.hide}>
        <Row>
          <Col span={11}>
            <GooglePlacesComponent
              addressField={AddressFields.PICKUP_ADDRESS}
              deliveryType={DeliveryType.RITIRO}
              hideName
            />
          </Col>
          <Col span={11} offset={2}>
            <GooglePlacesComponent
              addressField={AddressFields.SHIPPING_ADDRESS}
              deliveryType={DeliveryType.CONSEGNA}
              hideName
            />
          </Col>
        </Row>
        {countArray.map((index) => (
          <PalletDetails
            arrayLength={countArray.length}
            index={index}
            key={index}
            isDeletePallet={isDeletePallet}
            setCountArray={onDeletePallet}
          />
        ))}
        <Button
          type="link"
          icon={<PlusOutlined/>}
          className={style.upload}
          onClick={() => {
            onAddPallet();
            setIsDeletePallet(true);
          }
          }
        >
          {t('forms.newEstimation.add_pallet')}
        </Button>
        <div className={style.date_container}>
          <Form.Item
            label={t('forms.shipmentRequest.start_shipment_date')}
            name="start_shipment_date"
            rules={[
              { required: true, message: t('validation.date_pronto_merce') },
              ({ getFieldValue }) => ({
                // eslint-disable-next-line @typescript-eslint/no-shadow
                validator(_, value) {
                  if (getFieldValue('withdrawal_date') && value && moment(getFieldValue('withdrawal_date')) < value) {
                    return Promise.reject(new Error(t('validation.start_shipment_date')));
                  }
                  if (!value) {
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              disabledDate={(current) => (
                current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
              )}
              format="DD/MM/YYYY"
              className={style.date_width}
            />
          </Form.Item>
          <Form.Item
            name="start_shipment_time"
            label={t('forms.shipmentRequest.start_shipment_time')}
            rules={[
              { required: true, message: t('validation.empty') },
            ]}
          >
            <TimePicker format={'HH:mm'} />
          </Form.Item>
          <Form.Item
            label={t('forms.shipmentRequest.withdrawal_date_guest')}
            name="withdrawal_date"
            className={style.form_item_date}
            rules={
              [
                ({ getFieldValue }) => ({
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  validator(_, value) {
                    if (getFieldValue('start_shipment_date') && value && moment(getFieldValue('start_shipment_date')) > value) {
                      return Promise.reject(new Error(t('validation.withdrawal_date')));
                    }
                    if (!value) {
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                })]}
          >
            <DatePicker
              disabledDate={(current) => (
                current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
              )}
              format="DD/MM/YYYY"
              className={style.date_width}
            />
          </Form.Item>
        </div>
      </div>
      <div className={firstStep ? style.hide : style.visible}>
        {extraServices && <ShipmentRequestStepTwo
          facchinaggioList={facchinaggioList}
          contrassegnoList={contrassegnoList}
          validate={!firstStep}
          setFirstStep={onChangeStep}
          extraServices={extraServices}
          pallets={pallets}
        />}
      </div>
      {
        firstStep
          ? <CustomButton
            className={style.shipmentBtn}
            type="primary"
            htmlType="button"
            onClick={onChangeStep}
          >
            {t('forms.shipmentRequest.requestPrice')}
          </CustomButton>
          : <Form.Item>
            <CustomButton
              className={style.shipmentBtn}
              type="primary"
              htmlType="submit"
            >
              {t('forms.shipmentRequest.confirmRequest')}
            </CustomButton>
          </Form.Item>
      }
    </Form>
  );
};

export default ShipmentRequestForm;
